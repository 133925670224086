<template>
  <div :class="['header', languageClass]">
    <div class="header-section">
      <div class="header-gnb1">
        <div class="header-logo-container">
          <router-link to="/">
            <img src="../assets/gfyhealth_logo2.png" alt="gfyhealth logo" />
          </router-link>
        </div>
        <div class="header-menu">
          <!-- 회사 메뉴 -->
          <router-link
            to="/company/ceomessage"
            :class="{ active: activeMenu === 'company' }"
            @mouseover="showSubMenu('company')"
          >
            {{ $t("company") }}
          </router-link>

          <!-- 솔루션 메뉴 -->
          <router-link
            to="/solutions/gfyjiggy"
            :class="{ active: activeMenu === 'solutions' }"
            @mouseover="showSubMenu('solutions')"
          >
            {{ $t("solutions") }}
          </router-link>

          <!-- 기술 메뉴 -->
          <router-link
            to="/technology/kiosk"
            :class="{ active: activeMenu === 'technology' }"
            @mouseover="showSubMenu('technology')"
          >
            {{ $t("technology") }}
          </router-link>

          <!-- 연락처 메뉴 -->
          <router-link to="/contact" @mouseover="hideSubMenu">
            {{ $t("contact2") }}
          </router-link>
        </div>
        <div class="header-submenu5">
          <!-- 언어 선택 -->
          <div
            @click="changeLanguage('ko')"
            :class="{ active: activeLanguage === 'ko' }"
          >
            KOR
          </div>
          <span>|</span>
          <div
            @click="changeLanguage('en')"
            :class="{ active: activeLanguage === 'en' }"
          >
            ENG
          </div>
          <!-- <span>|</span>
          <div
            @click="changeLanguage('zh')"
            :class="{ active: activeLanguage === 'zh' }"
          >
            CHA
          </div> -->
        </div>
      </div>

      <!-- 모바일 메뉴 아이콘 -->
      <div class="header-gnb2">
        <div class="header-logo-container">
          <router-link to="/">
            <img src="../assets/gfyhealth_logo2.png" alt="gfyhealth logo" />
          </router-link>
        </div>
        <div class="sidemenu-menubtn-container" @click="toggleSideMenu">
          <img src="../assets/menu_btn.png" alt="sidemenu btn" />
        </div>
      </div>
    </div>

    <!-- 서브 메뉴 -->
    <div
      class="header-submenu-section"
      v-show="subMenuVisible"
      @mouseleave="hideSubMenu"
    >
      <!-- 회사 서브 메뉴 -->
      <div class="header-submenu1" v-show="activeMenu === 'company'">
        <div class="submenu-wrapper">
          <router-link to="/company/ceomessage">{{
            $t("ceomessage")
          }}</router-link>
          <router-link to="/company/ability">{{ $t("ability") }}</router-link>
          <router-link to="/company/history">{{ $t("history") }}</router-link>
          <router-link to="/company/patent">{{ $t("patent") }}</router-link>
        </div>
      </div>

      <!-- 솔루션 서브 메뉴 -->
      <div class="header-submenu2" v-show="activeMenu === 'solutions'">
        <div class="submenu-wrapper">
          <router-link to="/solutions/electroceuticals">
            {{ $t("electroceuticals") }}
          </router-link>
          <router-link to="/solutions/sleeptech">
            {{ $t("sleeptech") }}
          </router-link>
          <router-link to="/solutions/gfyjiggy">
            {{ $t("gfyjiggy") }}
          </router-link>
        </div>
      </div>

      <!-- 기술 서브 메뉴 -->
      <div class="header-submenu3" v-show="activeMenu === 'technology'">
        <div class="submenu-wrapper">
          <router-link to="/technology/kiosk">{{ $t("kiosk") }}</router-link>
          <router-link to="/technology/fallDetector">{{
            $t("fallDetector")
          }}</router-link>
          <router-link to="/technology/edgeBox">{{
            $t("edgeBox")
          }}</router-link>
          <router-link to="/technology/mEdgeBox">{{
            $t("mEdgeBox")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- 오버레이 -->
  <div
    class="sidemenu-overlay"
    v-show="sideMenuVisible"
    @click="toggleSideMenu"
  ></div>

  <!-- 사이드 메뉴 -->
  <SideMenu
    v-if="sideMenuVisible"
    @close="toggleSideMenu"
    :active-language="activeLanguage"
    @language-changed="changeLanguage"
  />
</template>

<script>
import SideMenu from "./SideMenu.vue";
export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      subMenuVisible: false,
      sideMenuVisible: false,
      activeMenu: "",
      activeLanguage: "ko", // 기본 언어는 'ko'로 설정
    };
  },
  computed: {
    languageClass() {
      return `lang-${this.$i18n.locale}`;
    },
  },
  created() {
    // 페이지가 처음 로드될 때는 sessionStorage에서 언어를 가져오고, 없으면 기본 언어로 ko 설정
    const savedLanguage = window.sessionStorage.getItem("userLanguage");
    if (savedLanguage) {
      this.changeLanguage(savedLanguage); // 세션 스토리지에서 언어를 설정
    } else {
      this.changeLanguage("ko"); // 세션에 저장된 언어가 없으면 기본 언어를 'ko'로 설정
    }
  },
  methods: {
    showSubMenu(menu) {
      this.activeMenu = menu;
      this.subMenuVisible = true;
    },
    hideSubMenu() {
      this.subMenuVisible = false;
      this.activeMenu = "";
    },
    toggleSideMenu() {
      this.sideMenuVisible = !this.sideMenuVisible;
    },
    handleResize() {
      if (window.innerWidth > 1200 && this.sideMenuVisible) {
        this.sideMenuVisible = false;
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.activeLanguage = lang;
      window.sessionStorage.setItem("userLanguage", lang); // 선택한 언어를 세션 스토리지에 저장
    },
    resetToKorean() {
      // 창을 닫았다가 열면 항상 언어를 ko로 초기화
      this.changeLanguage("ko");
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);

    // 창을 닫았다가 다시 열면 무조건 ko로 설정
    window.addEventListener("beforeunload", this.resetToKorean);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("beforeunload", this.resetToKorean); // 언로드 이벤트 리스너 해제
  },
};
</script>

<style>
@import "../assets/css/header.css";
</style>
